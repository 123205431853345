import "./App.css";
import { useEffect, useContext, useState } from "react";
import { AuthContext } from "./context/AuthContext";
import { Routes, Route, Navigate } from "react-router-dom";
import Home from "./pages/Home/Home";
import TopBar from "./components/TopBar/TopBar";
import Footer from "./components/Footer/Footer";
import Login from "./pages/Login/Login";
import Banner from "./components/Banner/Banner";
import { authUrl } from "./utils/authRequest";
import { ACTION_TYPES, AUTH_STATUS, EVENT_STATUS, REDIRECT_URL } from "./utils/definitions";
import { serverMode } from "./utils/config";
import Splash from "./pages/Splash/Splash";
import { delay } from "./utils/helper";
import Landing from "./pages/Landing/Landing";
import CheckInHome from "./pages/CheckInHome/CheckInHome";
import requestUrl from "./utils/requestMethods";

function App() {
  const { user, dispatch } = useContext(AuthContext);
  const [tokenValid, setTokenValid] = useState(false)
  const [authStatus, setAuthStatus] = useState('')
  const [eventInfo, setEventInfo] = useState(null)

  const checkToken = async () => {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: "same-origin",
      };
      const res = await authUrl.get("/verifyTokenResponse", config)
      delay(1000).then( () => {
        setAuthStatus(AUTH_STATUS.AUTH_AUTHENTICATED)
        delay(1000).then( async () => {
          setTokenValid(true)
          const loadAssoc = await loadAssociate()
          const event = await getEvent()
        })
      } )
    } catch (error) {
      console.log(error)
      delay(1000).then( () => {
        setAuthStatus(AUTH_STATUS.AUTH_NOT_AUTHENTICATED)
        delay(1000).then( ()=> 
          window.location.href = serverMode ? REDIRECT_URL.AUTH_PROD : REDIRECT_URL.AUTH_LOCAL
        )
      })
    }
  }
  const loadAssociate = async () => {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: "same-origin",
      };
      const res = await authUrl.get("/authAssociateLogin", config)
      dispatch({ type: ACTION_TYPES.SUCCESS, payload: res.data });
      return res.data
    } catch (error) {
      console.log(error)
    }
  }
  const getEvent = async () => {
    try {
        let config = {
            headers: {
                "Content-Type": "application/json",
            },
            withCredentials: "same-origin",
        }
        const { data } = await requestUrl.get(`/api/checkin/getEvent/${EVENT_STATUS.ACTIVE}`, config)
        console.log(data)
        setEventInfo(data)
        return data
    } catch (error) {
        console.log(error)
    }
}
  useEffect( () => {
    const onPageLoad = () => {
      checkToken()
    };
    if (document.readyState === 'complete') {
        onPageLoad();
    } else {
        window.addEventListener('load', onPageLoad);
        return () => window.removeEventListener('load', onPageLoad);
    }
  }, [])
  return (
    <>
      {
        ((tokenValid && (eventInfo?.access.includes(user?.domainId) || user?.tower === 'Delivery Excellence'))) ? (
          <div className="appContainer">
            <TopBar />
            <CheckInHome eventData={eventInfo} />
          </div>
        )
         : 
        (
          <Splash authStatus={authStatus}/>
        )
      }
    </>
  );
}

export default App;
